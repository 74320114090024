.home {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h2 {
        color: #D0AC01;
        font-size: 30px;
    }

    h2,
    .address {
        text-align: center;

        @media (max-width: 768px) {
            font-size: 24px;
        }

        @media (max-width: 523px) {
            font-size: 18px;
        }

        @media (max-width: 374px) {
            font-weight: lighter;
        }
    }

    p {
        color: #808080;
    }

    .welcome {

        .title {
            color: #5C3314;
            text-align: center;
            font-family: "Indie Flower";
            font-weight: 400;
            font-style: normal;
            font-size: 80px;
            padding: 40px;

            @media (max-width: 768px) {
                font-size: 60px;
            }

            @media (max-width: 523px) {
                font-size: 40px;
            }

            @media (max-width: 374px) {
                font-size: 20px;
                font-weight: bolder;
            }
        }

    }

    .about-us {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 0;
        height: fit-content;
        align-items: stretch;
        overflow: visible;
        padding-right: 40px;

        .image {
            overflow: hidden;
            box-shadow: 11px 10px 9px -4px rgba(0, 0, 0, 0.57);
            width: 55%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .description {
            width: 40%;
            margin-top: 40px;
            margin-bottom: 40px;
            text-align: center;
            align-items: center;

            @media (min-width: 1500px) {
                margin-top: 80px;
                margin-bottom: 80px;
            }

            .text {
                text-align: justify;
                margin-top: 40px;
                margin-bottom: 40px;
                font-weight: normal;
                font-size: 18px;
            }

            .ref {
                margin: auto;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;
            }
        }

        @media (max-width: 1000px) {
            flex-direction: column-reverse;
            align-items: center;
            padding: 0;

            .image {
                width: 100%;
                border-radius: 0;
                box-shadow: 0px 8px 6px -5px rgba(0, 0, 0, 0.51);
            }

            .description {
                width: 90%;
                margin-top: 0;

                .ref {
                    justify-content: flex-end;
                }
            }
        }

    }

    .activities {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        p {
            width: 40%;
            text-align: center;
            font-weight: normal;
            font-size: 18px;
            margin-top: 40px;
            margin-bottom: 80px;

            @media (max-width: 1000px) {
                width: 90%;
            }
        }

    }

    .exploration {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        h2 {
            text-align: center;
        }

        p {
            width: 40%;
            text-align: center;
            font-weight: normal;
            font-size: 18px;
            margin-top: 40px;
            margin-bottom: 40px;

            @media (max-width: 1000px) {
                width: 90%;
            }
        }
    }

    .services-1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        overflow: visible;

        .imagecontainer {
            width: 50%;
            border-top-right-radius: 10px;
            box-shadow: none;
            transition: box-shadow 0.3s ease-in-out;
            overflow: hidden;
        }

        .image {
            width: 100%;
            height: 100%;
        }

        .card {
            width: 50%;
            font-size: 18px;
            font-weight: normal;
            background-color: transparent;
            padding: 50px;
            margin: auto;

            h3 {
                color: #5C3314;
                font-size: 18px;
                margin: 20px;
                text-align: center;
            }

            .description {
                text-align: center;
                color: black;
                font-weight: bold;
                margin: 10px;
            }

            p {
                text-align: center;
                font-size: 18px;
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }

        @media (max-width: 930px) {
            flex-direction: column-reverse;
            position: relative;
            overflow: hidden;
            padding-top: 40px;
            padding-bottom: 40px;
            margin-bottom: 5px;

            image    .imagecontainer {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }

            .image {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -99;
                opacity: 0.2;
                height: 100%;
                width: 100%;
            }

            .card {
                width: 100%;

                .description {
                    font-style: normal;
                }

                p {
                    color: rgb(14, 13, 13);
                    font-weight: lighter;
                }
            }

        }

        &:hover {
            .imagecontainer {
                box-shadow: 5px 0px 10px -1px rgba(0, 0, 0, 0.75);
            }
        }
    }

    .services-2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 20px;
        align-items: stretch;
        overflow: visible;

        .imagecontainer {
            width: 50%;
            border-bottom-left-radius: 10px;
            box-shadow: none;
            transition: box-shadow 0.3s ease-in-out;
            overflow: hidden;
        }

        .image {
            width: 100%;
            height: 100%;
        }

        .card {
            width: 50%;
            font-size: 18px;
            font-weight: normal;
            background-color: transparent;
            padding: 50px;
            margin: auto;

            h3 {
                color: #5C3314;
                font-size: 18px;
                margin: 20px;
                text-align: center;
            }

            .description {
                text-align: center;
                color: black;
                font-weight: bold;
                margin: 10px;
            }

            p {
                text-align: center;
                font-size: 18px;
                margin-top: 40px;
                margin-bottom: 40px;
            }

        }

        @media (max-width: 930px) {
            flex-direction: column-reverse;
            position: relative;
            overflow: hidden;
            padding-top: 40px;
            padding-bottom: 40px;
            margin-bottom: 5px;

            .imagecontainer {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }

            .image {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -99;
                opacity: 0.2;
                height: 100%;
                width: 100%;
            }

            .card {
                width: 100%;

                .description {
                    font-style: normal;
                }

                p {
                    color: rgb(14, 13, 13);
                    font-weight: lighter;
                }
            }

        }

        &:hover {
            .imagecontainer {
                box-shadow: 5px 0px 10px -1px rgba(0, 0, 0, 0.75);
            }
        }
    }

    .landscape {
        width: 100%;
        height: 400px;
        overflow: hidden;

        background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../../../public/images/sable_blanc_18.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        .landscape-image {
            width: auto;
            height: 70%;
            background-color: #fff;
            padding: 10px; 
            border-radius: 10px;
        }

        .description-contact-container {
            text-align: center;
            .description {
                text-align: center;
                max-width: 500px;
                margin: auto;
                color: #fff;
                font-size: 20px;
                margin-bottom: 40px;
            }
        }
        @media (max-width: 800px) {
            display: block;
            height: max-content;
            padding: 20px;
            .landscape-image {
                display: block;
                height: auto;
                width: 150px;
                margin: auto;
            }
            .description-contact-container {
                margin-top: 20px;
            }
        }
    }
}