.carousel-container {
    width: 100%;
    display: block;
    background-color: transparent;
    .paper {
        overflow: hidden;
        background-color: transparent;
    }
    .carousel-image {
        width: 100vw;
    }
}