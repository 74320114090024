.box {
    display: block;
    margin: 0;
    padding: 0;
    overflow: visible;
    margin-top: 80px;
    margin-bottom: 80px;

    @media (min-width: 1500px) {
        margin-top: 120px;
        margin-bottom: 120px;
    }
}