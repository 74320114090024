.services-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .services-description {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-top: 40px;
        margin-bottom: 80px;
        overflow: visible;
        padding: 20px;
        @media (min-width: 764px) {
            gap: 80px;
        }

        .services-image-container {
            width: 200px;
            height: 200px;
            border-radius: 100%;
            overflow: hidden;
            box-shadow: 5px 5px 10px -4px rgba(0, 0, 0, 0.57);
        }

        img {
            height: 100%;
            width: auto;
            transform: translateX(-60px);        
        }

        .services-text-description {
            width: 100%;
            @media (min-width: 764px) {
                width: 40%;
            }
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            h2 {
                color: #5C3314;
                font-size: 18px;
            }

            p {
                color: #808080;
                font-size: 18px;
            }
        }

    }

    .services-photo {
        margin-top: 80px;
        margin-bottom: 3px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            font-size: 30px;
            color: #D0AC01;
            text-align: center;
        }

        img {
            width: 500px;
            height: 300px;
            cursor: pointer;
            scale: 1;
            transition: scale 3s linear;
            @media (max-width: 506px) {
                width: 100%;
                height: auto;
            }
        }

        img:hover {
            scale: 1.1;
        }

    }
}