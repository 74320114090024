.visible {
    .otherlinks {
        display: flex;
        gap: 20px;
    }
    @media (max-width: 750px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: relative;
        padding-top: 120px;
        padding-bottom: 10px;
        .links {
            width: 90%;
            display: flex;
            justify-content: space-around;
            .nav-item {
                min-width: 80px;
                padding: 0px;
                margin: 10px;
            }
        }
        .otherlinks {
            position: absolute;
            top: 10px;
            right: 20px;
        }
        .logocontainer {
            position: absolute;
            top: 10px;
            left: 20px;
        }
    }
    @media (max-width: 425px) {
        .otherlinks {
            display: flex;
            flex-direction: column;
            gap: 0px;
        }
    }
}