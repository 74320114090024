.contact-container {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    @media (max-width: 750px) {
        margin-top: 200px;
        flex-direction: column;
        align-items: center;
    }
    form {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        .title {
            color: hsl(26, 64%, 22%);
            font-size: 20px;
            text-align: center;
            margin-bottom: 20px;
        }
        .description {
            width: 100%;
            color: #808080;
            font-size: 18px;
            font-weight: normal;
            text-align: center;
        }
        @media (min-width: 1024px) {
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            padding-left: 80px;
            padding-right: 80px;
            .title {
                margin-bottom: 40px;
            }
            .description {
                font-weight: normal;
                margin-bottom: 40px;
            }
        }
    }
    .image-container {
        width: 55%;
        background-image: url('../../../public/images/sable_blanc_12.jpg');
        background-size: cover;
        background-position: center;
    }
}