.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
    padding-bottom: 10px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(#5C3314, #5C3314);
    color: white;
    font-weight: lighter;
    font-size: 16px;
    font-weight: normal;
    padding: 40px;
    padding-bottom: 10px;

    .information {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 0;
        margin: 0;

        .information-element {
            min-width: 200px;
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        @media (max-width: 1000px) {
            width: 90%;
        }
    }
}